/* You can add global styles to this file, and also import other style files */

@import 'variables';
// @import "material.app.theme.scss";


:root {
    --fs-400: 0.9375rem;
    ;
    --fs-450: 1.125rem;
    --fs-500: 1.25rem;
    --fs-600: 1.375rem;
    --fs-700: 1.5625rem;

    --fw-normal: 400;
    --fw-medium: 500;
    --fw-semi-bold: 600;
    --fw-bold: 700;
    --fw-extra-bold: 800;
}

body {
    height: 100svh;
    background-color: $primary_dark;
    margin: 0;
    overflow-y: hidden;
}

// ***************************** Tooltip ********************************

.mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: #{$billing-primary-light1};
    // #{$billing-primary-light1}
    --mdc-plain-tooltip-supporting-text-color: black;
    --mdc-plain-tooltip-supporting-text-size: 15px;
    --mdc-plain-tooltip-container-shape: 15px;
    --mdc-plain-tooltip-supporting-text-weight: 300;

    line-height: 18px;

    .mdc-tooltip__surface {
        padding-inline: 0.81rem;
        border: 1px solid $orange;
    }
}

.mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 30px;

    .mat-mdc-dialog-surface {
        padding: 20px;
    }
}

.wide-mat-dialog .mdc-dialog__container {
    --mdc-dialog-container-color: #{$billing-secondary-light};

    .mdc-dialog__surface {
        padding: 6rem 8rem;
    }
}

h1 {
    color: $header_dark;
    margin: 0px 0px 0px 0px !important;
}

.mat-primary .mat-mdc-option.mat-mdc-selected {
    color: $orange !important;
    background-color: white !important;
}

.mat-primary .mat-mdc-option:hover {
    background-color: $light_background !important;
}

// ***************************** ComboBox ********************************
.single.mat-mdc-select-panel {
    left: 6px;
    position: absolute;
    top: 23px;
    min-width: 100% !important;
    max-width: calc(100% + 22px) !important;
}

.multiple.mat-mdc-select-panel {
    position: absolute;
    top: 23px;
    left: 28px;
    min-width: 100% !important;
    max-width: calc(100% + 22px) !important;
}

// ***************************** Form Field ********************************
.mdc-text-field:not(.mdc-text-field--disabled) {
    .mdc-floating-label {
        .mat-mdc-form-field-required-marker {
            color: $red;
        }
    }
}

mat-form-field {
    width: 100%;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-bottom: 10px;
    min-height: 50px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
    min-height: 40px;
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
    border-color: $orange !important;
    background-color: $orange !important;
    border-radius: 4px;
}

button.mat-mdc-mini-fab:not(.mdc-fab--extended) {
    --mdc-fab-container-shape: 12px;
    height: 30px;

    &.mat-primary {
        --mdc-fab-container-color: #{$primary_dark};
    }
}

button.mat-mdc-unelevated-button:not(:disabled) {
    border-radius: 1.25rem;
    height: 40px;

    &.mat-primary {
        background-color: $primary_dark;
    }

    &.mat-accent {
        background-color: $billing-secondary-light;
        color: $primary-dark;
    }

    &.mat-warn {
        background-color: $orange;
        color: white;
        height: 1.9375rem;
    }
}

.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active {
    color: $primary-dark !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
    background-color: #e5f3ee !important;
    font-weight: 500;
}