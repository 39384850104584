@use './variables.scss' as *;

:root {
  --gap :20px;
}

@mixin define-icon-style($height){
  height: $height !important;
  cursor: pointer;
}

.mat-icon-24 {
  @include define-icon-style(24px);
}

.mat-icon-16 {
  @include define-icon-style(16px);
}

.mat-icon-20 {
  @include define-icon-style(20px);
}

.bold-text {
  font-weight: 500;
}

.grid-flow {
  display: grid;
  align-content: start;
  gap: 1rem;
}

.flex-group {
  display: flex;
  align-items: center;
  align-content: center;
  gap: .25rem;
  flex-wrap: wrap;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

button.refresh-btn {
  flex: 0 0 auto;
  min-width: 0;
  align-self: baseline;
 
  .mat-icon {
    vertical-align: middle;
    height: auto;
    font-size: 24px;
    margin: 0;
    width: auto;
  }

  &.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 55%;
    left: 50%;
    width: 20px;
    height: 18px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
  }
}

.middle {
  vertical-align: middle;
}

button.search-btn {
  letter-spacing: normal;

  .mat-icon {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }
}

.filter-row {
  max-width: calc(100vw - 170px);
  height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;

  mat-form-field {
    margin-right: 4px;

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      min-height: 37px;
      padding-top: 9px;
      padding-bottom: 5px;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 50%;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      padding: 6px;
      height: 24px;
    }
  }
}

div[mat-card-actions], div[mat-dialog-actions] {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
}

p.no-results-found-msg {
  color: gray;
  font-size: 20px;
  text-align: center;
  margin: 20px;
}

h3.tal-sub-header {
  border-radius: 7px;
  font-size: 17px;
  font-weight: 500;
  margin: 15px 15px 15px 0;
  background-color: $primary_light;
  padding: 5px 10px;
  min-width: 7rem;
  // width: 30%; //TODO(change)
}
