
$primary_dark:#018086;
$primary_dark_lighten: #029299;
$primary_light:rgba(1, 128, 134, 0.15);
$green: #00875A;
$orange: #FFB550;
$orange_light:rgba(250, 240, 228, 1);
$gray:#708D8E;
$light-gray:#bdb8b8;
$dark-gray: rgba(112, 141, 142, 1);
$black:#002030;
$white:white;
$header_dark: #001729;
$light_primary: #C3D6D7;
$light_background: rgba(0, 0, 0, 0.04);
$red:#FF5050;
$light-blue: rgba($primary_dark, 0.15);
$light-gray:#EFEFEF;
$dark-gray:#ADB9B5;;

$red1:#ff5050a0;
$green1: #00875Aa0;
$orange1: #ffb650a0;

// Table
$td-border-radius: 10px;
$td-border-thickness: 1.5px;

// Billing
$billing-primary-light1:#FFEFA4;
$billing-primary-light2:#FFFBE8;

$billing-primary-dark1:#FD8D3B;
$billing-primary-dark3:#FFE8CA;

$billing-secondary-light:#D9ECED;

$billing-green:#1B8D92;

