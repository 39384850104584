@use "/src/style/variables.scss" as *;

:root {
    --gap: 7px;
    --table-row-radius: 5px;
    --default-shadow: 0px 0px 30px 0px #CFCFCF;
    --default-border-radius: 19px;
}

.dot-seperator {
    --_dot-color: var(--dot-color, #D1D3D4);
    position: relative;

    &:after {
        --size: 0.25rem;

        content: "";
        position: absolute;
        width: var(--size);
        transform: translateX(-50%);
        right: 0;
        top: 0.5rem;
        aspect-ratio: 1;
        background: var(--_dot-color);
        border-radius: 50%;
    }
}


.billing-header-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    margin: 2rem 0;
    background-color: $billing-secondary-light;
    padding: 24px 0;
    border-radius: var(--default-border-radius);
    box-shadow: var(--default-shadow);
    font-size: var(--fs-450);
    font-weight: var(--fw-medium);

    .header-info {
        padding-inline: 1.5rem;
        padding-block: 0.75rem;
    }
}

.divider {
    --_divider-color: var(--divider-color, #{$primary-dark});

    border-bottom: 2px solid var(--_divider-color);
    position: relative;
    width: 100%;
    margin: 5px 0;

    &:before,
    &:after {
        position: absolute;
        bottom: -6px;
        left: 0;
        height: 10px;
        width: 10px;
        background: var(--_divider-color);
        content: "";
        border-radius: 5px;
    }

    &:after {
        right: 0;
        left: auto;
    }
}


.small-divider {
    --_divider-color: var(--divider-color, #{$primary-dark});
    border-bottom: 1px solid var(--_divider-color);
    position: relative;
    width: 100%;
    margin: 5px 0;

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;
        height: 5px;
        aspect-ratio: 1;
        background: var(--_divider-color);
        border-radius: 5px;
        transform: translateY(-2px);
    }

    &:after {
        right: 0;
        left: auto;
    }
}

.total {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;

    &:after {
        border-bottom: 1px dashed black;
        content: '';
        flex: 1;
    }
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// .alternate-table {
//     border-spacing: 0 var(--gap) !important;

//     .mat-mdc-row {
//         height: 38px;

//         &:nth-child(even) {
//             background-color: $billing-primary-light2;
//         }

//         &:hover,
//         &:focus {
//             background-color: $billing-secondary-light !important;

//             td.mat-mdc-cell {
//                 border-color: $primary-dark;
//             }
//         }
//     }
// }

.bg-primary {
    background-color: $billing-primary-light1 ;
}

.bg-secondary {
    background-color: $billing-primary-light2 ;
}

.bg-accent {
    background-color: $primary_dark ;
    color: white;
}

.bg-secondary-light {
    background-color: $billing-secondary-light;
}

.bg-white {
    background-color: white;
}