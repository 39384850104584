@import "./variables.scss";

thead th {
  border-top-style: none;
  font-weight: normal !important;
  border-bottom-width: 1px;
}

table td {
  vertical-align: middle;
  border: none;
}

th.mat-mdc-header-cell {
  border-bottom-width: 0px !important;
  position: sticky;
  top: 0;
  background: white;
}

.mat-mdc-header-cell {
  font-size: 14px;
  color: $gray !important;
}

table {
  --_table-row-height: var(--table-row-height, 52px);
  --_table-border-color: var(--table-border-color, transparent);
  --_table-border-radius: var(--table-border-radius, 10px);
  --_table-border-width: var(--_table-border-width, 1px);

  width: 100%;
  border-spacing: 0 var(--gap) !important;

  tr.mat-mdc-row {
    height: var(--_table-row-height) !important;
    transition: all 100ms ease-in-out;
    cursor: pointer;

    td.mat-mdc-cell {
      border-block-width: var(--_table-border-width);
      border-inline-width:0;
      border-color: var(--_table-border-color);
      border-style: solid;
    }

    td.mat-mdc-cell:first-child {
      border-left-width: var(--_table-border-width);
      border-top-left-radius: var(--_table-border-radius);
      border-bottom-left-radius: var(--_table-border-radius);
    }

    td.mat-mdc-cell:last-child {
      border-right-width: var(--_table-border-width);
      border-top-right-radius: var(--_table-border-radius);
      border-bottom-right-radius: var(--_table-border-radius);
    }

    &:nth-child(even) {
      background-color: $billing-primary-light2;
    }
    // MD removes the border of the last row
    &:last-child .mdc-data-table__cell  {
      // border-bottom: var(--_table-border-width) solid var(--_table-border-color);
    }

    &:hover,
    &:focus {
      background-color: $billing-secondary-light !important;

      td.mat-mdc-cell {
        border-color: $primary-dark;
      }
    }
  }
}

.densed-table {
  --table-row-height: 2.375rem;
}

.cards-table {
  --table-border-color: #{$billing-primary-light1};
  --table-border-width: 2px;
  --table-row-height: 5.3215rem;
  --table-border-radius: 0.875rem;
  --gap: 1.06rem;

  td:hover {
    --table-border-width: 1px;
  }
}

a {
  color: $primary_dark;
  cursor: pointer;
}

@mixin form-layout($columns, $rows) {
  .fields-container {
    display: grid;
    gap: var(--gap) calc(var(--gap) + 15px);
    grid-template: repeat($columns, 1fr) / repeat($rows, 1fr);
  }
}

.flex-container {
  display: flex;
  column-gap: var(--gap);
  align-items: center; //baseline
}


.add-record-btn {
  margin-top: -80px;
  float: right;
}

h1 {
  display: inline-block;
  font-weight: 500;
  padding-bottom: 10px;
  padding-left: 12px;
}

.save {
  padding: 3px;
  padding-left: 33px;
  margin-right: 3px;
  padding-right: 33px;
}

.scroll {
  max-height: calc(100vh - 150px - 5rem);
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  width: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: $gray;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}


th.mat-mdc-header-cell:first-of-type {
  z-index: 1000;
}

.mat-divider {
  margin-top: 12px;
  // padding-bottom: 12px;
}

button.add {
  margin-top: 0;
  position: absolute;
  right: 1.5%;
  padding-top: 4px;
  padding-bottom: 4px;
}

p.no-data-found {
  color: gray;
  font-size: 20px;
  text-align: center;
  margin: 20px;
}